<template>
  <div id="contact">
    <header>
      <div class="headerTop">
        <div class="container">
          <div class="logo">
            <h1>
              <img src="@/assets/images/logo.png" alt="logo" />
            </h1>
          </div>
          <ul>
            <li><router-link to="/">首页</router-link></li>
            <li><router-link to="wisdom">智量百业</router-link></li>
            <li><router-link to="shop">同城好店</router-link></li>
            <li><router-link to="assistant">抖微助手</router-link></li>
            <li><router-link to="flyfishoutlets">飞鱼系统</router-link></li>
            <li><router-link to="commerce">跨境电商</router-link></li>
            <li class="cur">
              <router-link to="contact">联系我们</router-link>
            </li>
            <li><router-link to="about">关于我们</router-link></li>
          </ul>
        </div>
      </div>
      <img src="@/assets/images/contact-banner.jpg" alt="" />
    </header>
    <div class="contact pd60">
      <div class="main">
        <div class="container">
          <img src="@/assets/images/contact-1.png" alt="" />
          <span>开店合作</span>
          <p>
            请将合作资料发送至邮箱：1487986688@qq.com
            或者加微信：13410419943
          </p>
        </div>
      </div>
      <div class="main">
        <div class="container">
          <img src="@/assets/images/contact-2.png" alt="" />
          <span>供应商合作</span>
          <p>请将产品资料发送至邮箱： supplychain@moyangfeng.com</p>
        </div>
      </div>
      <div class="main">
        <div class="container">
          <img src="@/assets/images/contact-3.png" alt="" />
          <span>售后客服</span>
          <p>电话： 4000-519-599 客服在线时间：9:30-21:30</p>
        </div>
      </div>
    </div>
    <footer>
      <div class="main container pd60">
        <div class="item">
          <h4>关于我们</h4>
          <p>公司介绍</p>
          <p>业务合作</p>
        </div>
        <div class="item">
          <h4>联系我们</h4>
          <p>邮箱：1487986688@qq.com</p>
          <p>电话（微信同名）：13410419943，周一至周日 9:30 - 22:30</p>
          <!-- <p>地址：深圳市福田区车公庙泰然八路安华工业区6栋613</p> -->
        </div>
        <div class="wx">
          <div class="wx-item">
            <img src="@/assets/images/wx-1.jpg" alt="" />
            <p>扫码关注公众号</p>
          </div>
          <div class="wx-item">
            <img src="@/assets/images/wx-2.jpg" alt="" />
            <p>业务合作</p>
          </div>
        </div>
      </div>
      <div class="copyright container pd60">
        <p>©2019-2024 深圳漠阳风科技有限公司 版权所有</p>
        <a href="http://beian.miit.gov.cn/">粤ICP备19157531号-2</a>
      </div>
    </footer>
  </div>
</template>
<script>
import '@/assets/css/base.css'
import '@/assets/css/style.css'
export default {}
</script>
